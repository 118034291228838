import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Box, Typography, Tabs, Tab, IconButton, TextField, Button, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import './InstructionModal.scss';
import { getCsrfToken } from '../../../services/csrf';
import ETL from "../../images/ETL-SB.png"

interface InstructionModalProps {
  open: boolean;
  handleClose: () => void;
}

interface Review {
  id: number;
  text: string;
  phone: string;
  recipient: string;
  created_at: string;
  approved: boolean;
  user: string;
  rating: number;
}

const categories = [
  'Отзывы для людей',
  'Отзывы о работниках EMCO',
  'Отзывы о волонтерах',
];

const InstructionModal: React.FC<InstructionModalProps> = ({ open, handleClose }) => {
  const [tabValue, setTabValue] = useState(0);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [reviewText, setReviewText] = useState('');
  const [phone, setPhone] = useState('');
  const [recipient, setRecipient] = useState('');
  const [message, setMessage] = useState('');
  const [userName, setUserName] = useState('');
  const [rating, setRating] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<string>('');

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      const response = await axios.get<Review[]>('http://localhost:8000/api/reviews/', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access')}`,
        },
      });
      setReviews(response.data.filter(review => review.approved));
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleReviewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReviewText(event.target.value);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };

  const handleRecipientChange = (event: SelectChangeEvent<string>) => {
    setRecipient(event.target.value);
  };

  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  const handleRatingChange = (index: number) => {
    setRating(index);
  };

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    setSelectedCategory(event.target.value);
  };

  const handleReviewSubmit = async () => {
    try {
      const csrfToken = getCsrfToken();
      await axios.post('http://localhost:8000/api/reviews/', {
        text: reviewText,
        phone,
        recipient,
        user: userName,
        rating,
        category: selectedCategory,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access')}`,
          'X-CSRFToken': csrfToken,
        },
        withCredentials: true,
      });
      setMessage('Ваш отзыв на модерации');
      setReviewText('');
      setPhone('');
      setRecipient('');
      setUserName('');
      setRating(0);
      setSelectedCategory('');
      fetchReviews();
    } catch (error) {
      console.error('Error submitting review:', error);
    }
  };

  const filteredReviews = selectedCategory
    ? reviews.filter(review => review.recipient === selectedCategory)
    : reviews;

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="instruction-modal">
        <IconButton onClick={handleClose} className="close-button">
          <CloseIcon />
        </IconButton>
        <Tabs value={tabValue} onChange={handleTabChange} className="tabs">
          <Tab label="О сайте" />
          <Tab label="Отзывы" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <Typography variant="h6" gutterBottom>
            О нашем проекте
          </Typography>
          <Typography variant="body1" paragraph>
            Этот сайт помощник по выявлению несанкционированных свалок. 
          </Typography>
          <Typography variant="body1" paragraph>
            Мы стремимся помочь обществу в борьбе с загрязнением окружающей среды, предоставляя удобные инструменты для маркировки и отчетности о несанкционированных свалках. С нашей помощью пользователи могут эффективно взаимодействовать с местными властями и экологическими организациями, чтобы быстро и эффективно устранять такие проблемы.
          </Typography>
          <Typography variant="body1" paragraph>
            Наши цели включают повышение осведомленности об экологических проблемах и содействие в их решении. Мы верим, что совместными усилиями можем сделать наш мир чище и лучше для будущих поколений.
          </Typography>
          <a href="https://emcotech.site" target="_blank" rel="noopener noreferrer">
            <img
              src={ETL} 
              className="image-link"
            />
          </a>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Typography>Здесь вы можете прочитать отзывы и оставить свой отзыв.</Typography>
          <div className="review-form">
            <TextField
              label="Ваше имя"
              value={userName}
              onChange={handleUserNameChange}
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Ваш телефон"
              value={phone}
              onChange={handlePhoneChange}
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="recipient-label">Адресат</InputLabel>
              <Select
                labelId="recipient-label"
                value={recipient}
                onChange={handleRecipientChange}
                label="Адресат"
              >
                <MenuItem value="Отзывы для людей">Отзывы для людей</MenuItem>
                <MenuItem value="Отзывы о работниках EMCO">Отзывы о работниках EMCO</MenuItem>
                <MenuItem value="Отзывы о волонтерах">Отзывы о волонтерах</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Ваш отзыв"
              multiline
              rows={4}
              value={reviewText}
              onChange={handleReviewChange}
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
            />

            <Button
              onClick={handleReviewSubmit}
              variant="contained"
              color="primary"
              disabled={!reviewText.trim() || !recipient || rating === 0}
              sx={{ mt: 2 }}
            >
              Оставить отзыв
            </Button>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="category-label">Категория</InputLabel>
              <Select
                labelId="category-label"
                value={selectedCategory}
                onChange={handleCategoryChange}
                label="Категория"
              >
                <MenuItem value="">Все</MenuItem>
                {categories.map((category, index) => (
                  <MenuItem key={index} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {message && <Typography sx={{ mt: 2, color: 'green', fontWeight: 'bold' }}>{message}</Typography>}
          </div>
          <div className="review-list">
            {filteredReviews.map((review, index) => (
              <div key={index} className="review-item">
                <div className="review-header">
                  <PersonIcon className="review-icon" />
                  <Typography variant="body2" className="review-username">
                    {review.user}
                  </Typography>
                </div>
                <Typography variant="body1">{review.text}</Typography>
                <div className="review-rating">
                  {[...Array(5)].map((_, index) => (
                    <StarIcon
                      key={index}
                      className={index < review.rating ? 'star-filled' : 'star-empty'}
                    />
                  ))}
                </div>
                <Typography variant="caption" color="textSecondary">
                  {new Date(review.created_at).toLocaleString()}
                </Typography>
              </div>
            ))}
          </div>
        </TabPanel>
      </Box>
    </Modal>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="tabpanel">
          {children}
        </Box>
      )}
    </div>
  );
};

export default InstructionModal;
